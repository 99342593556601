import { createRouter, createWebHistory } from "vue-router";
//import blog from "@/components/blog/blog.json";

const routes = [
	{
		path: "/",
		name: "home",
		component: () => import("../views/HomePage.vue"),
	},
	{
		path: "/floor-plans",
		name: "floor-plans",
		component: () => import("../views/FloorPlans.vue"),
	},
	{
		path: "/community-spaces",
		name: "community-spaces",
		component: () => import("../views/CommunitySpaces.vue"),
	},
	{
		path: "/bc-lofts-story",
		name: "bc-lofts-story",
		component: () => import("../views/BCLoftStory.vue"),
	},
	{
		path: "/bozeman-montana",
		name: "bozeman-montana",
		component: () => import("../views/BozemanMontana.vue"),
	},
	{
		path: "/contact",
		name: "contact",
		component: () => import("../views/ContactUs.vue"),
	},
];

const router = createRouter({
	// history: createWebHistory(process.env.BASE_URL),
	history: createWebHistory(`/`),
	base: process.env.BASE_URL,
	routes,
});

export default router;
