<template>

  <Head>
    <title>BCLofts</title>
    <meta name="description" content="Creative Showcase Portfolio Template" />
  </Head>
  <LoadingPage />
  <DsnNavbar />
  <Options />
  <!-- <CursorGrowing /> -->
  <router-view :key="$route.fullPath" />
</template>

<script setup>
import { Head } from "@unhead/vue/components";
import LoadingPage from "@/components/loading/LoadingPage.vue";
import DsnNavbar from "@/components/navbar/DsnNavbar.vue";
import Options from "@/components/options/SelectOptions.vue";
// import CursorGrowing from "@/components/scroll-and-cursor/CursorGrowing.vue";
import { onUpdated } from "vue";
import { cursorGrowing } from "@/assets/global/global"

onUpdated(() => {
  cursorGrowing()
})
</script>
